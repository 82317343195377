import { Component, Mixins, Ref } from "vue-property-decorator";
import { isEmpty, isPositiveNumber, formatMoney } from "@helpers";
import { cardGroupModule } from "@store/namespaces";
import { CardGroupActions } from "@store/modules/card/modules/group/types";
import { VForm } from "@/types/vuetify";
import {
  MAX_NUM_CARDS_BATCH_ISSUE,
  MIN_NUM_CARDS_BATCH_ISSUE,
  MIN_AUTO_REFILL_AMOUNT,
  MIN_AUTO_REFILL_THRESHOLD,
  MAX_AUTO_REFILL_THRESHOLD,
  MAX_AUTO_REFILL_AMOUNT,
  MIN_AMOUNT_REFILL_CARD,
} from "@config/base";
import { CardType, CardGroup } from "@/types/card";
import API from "@api";
import { HtmlElementId } from "@/types/element";

import IssueCardMixin from "../../mixins/issue-card.mixin";

@Component
export default class IssuePrepaidCardForm extends Mixins(IssueCardMixin) {
  @Ref("formCardLimit") private readonly formCardLimitRef?: VForm;

  @cardGroupModule.Action("createCardGroup")
  private readonly createCardGroupAction!: CardGroupActions["createCardGroup"];

  private form: {
    numCards: number;
    depositAmount: string;
    autoRefillThreshold: number | null;
    autoRefillAmount: number | null;
    note: string;
    cardGroup: CardGroup | string | null;
  } = {
    note: "",
    depositAmount: "",
    numCards: 1,
    autoRefillThreshold: null,
    autoRefillAmount: null,
    cardGroup: null,
  };

  private completed = false;
  private loading = false;

  private get minDepositAmount() {
    return this.selectedBank?.minDepositAmount ?? MIN_AMOUNT_REFILL_CARD;
  }

  private get title() {
    return this.$vuetify.lang.t("$vuetify.dashboard.issue_card");
  }

  private get htmlElementId() {
    return {
      issueCardBaseForm: HtmlElementId.issueCardBaseForm,
      issueCardTotalInfo: HtmlElementId.issueCardTotalInfo,
    };
  }

  private get autoRefillEnabled() {
    return (
      this.form.autoRefillAmount !== null &&
      this.form.autoRefillThreshold !== null
    );
  }

  private set autoRefillEnabled(enabled) {
    this.form.autoRefillAmount = enabled ? MIN_AUTO_REFILL_AMOUNT : null;
    this.form.autoRefillThreshold = enabled ? MIN_AUTO_REFILL_THRESHOLD : null;
  }

  private get totalInfo() {
    const isValidNumCards =
      this.fieldRules.numCards(this.form.numCards.toString()) === true;

    const isValidDepositAmount =
      this.fieldRules.depositAmount(this.form.depositAmount) === true;

    const cardIssueCost =
      isValidNumCards && this.selectedBank
        ? this.form.numCards * this.selectedBank.cardIssueCost
        : 0;

    const fields: {
      text: string;
      value: number;
      bold?: boolean;
    }[] = [
      {
        text: this.$vuetify.lang.t("$vuetify.fields.card_issue"),
        value: cardIssueCost,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.fields.deposit_amount"),
        value:
          isValidNumCards && isValidDepositAmount
            ? +this.form.depositAmount * this.form.numCards
            : 0,
      },
    ];

    if (this.numFreeCards) {
      fields.push({
        text: this.$vuetify.lang.t("$vuetify.fields.promocode"),
        value: this.selectedBank
          ? -Math.min(
              this.numFreeCards * this.selectedBank.cardIssueCost,
              cardIssueCost
            )
          : 0,
      });
    }

    if (fields.length > 1) {
      const totalValue = fields.reduce((sum, { value }) => sum + value, 0);

      fields.push({
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.total"),
        value: totalValue,
        bold: true,
      });
    }

    return fields.map((item) => ({
      ...item,
      originalValue: item.value,
      value: formatMoney({
        value: item.value,
        currency: this.selectedBank?.currency,
      }),
    }));
  }

  private get numFreeCards() {
    return Math.max(
      this.profileFreeCardsTotalPromocode.bonus -
        this.profileFreeCardsTotalPromocode.bonusUsed,
      0
    );
  }

  private get cardTotalIssueCost() {
    const numCards = this.form.numCards;

    const cardTotalIssueCost = this.selectedBank
      ? Math.max(numCards - this.numFreeCards, 0) *
        this.selectedBank.cardIssueCost
      : 0;

    return cardTotalIssueCost;
  }

  private get calcAvailableBalance() {
    const { originalValue } = this.totalInfo[this.totalInfo.length - 1];

    return this.currentBalance - originalValue;
  }

  private get isInsufficientBalance() {
    return this.calcAvailableBalance < 0;
  }

  private get fieldRules() {
    return {
      required: (v: string) =>
        !isEmpty(v) || this.$vuetify.lang.t("$vuetify.errors.required"),
      isSafeInteger: (v: string) =>
        Number.isSafeInteger(+v) ||
        this.$vuetify.lang.t("$vuetify.errors.only_integer_number"),
      positiveNumber: (v: string) =>
        isPositiveNumber(v) ||
        this.$vuetify.lang.t("$vuetify.errors.positive_number"),
      depositAmount: (v: string) => {
        if (this.fieldRules.required(v) !== true) {
          return this.fieldRules.required(v);
        }

        if (this.fieldRules.positiveNumber(v) !== true) {
          return this.fieldRules.positiveNumber(v);
        }

        if (+v < this.minDepositAmount) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.min_amount",
            formatMoney({
              value: this.minDepositAmount,
              currency: this.selectedBank?.currency,
            })
          );
        }

        return true;
      },
      numCards: (v: string) => {
        if (this.fieldRules.required(v) !== true) {
          return this.fieldRules.required(v);
        }

        if (this.fieldRules.positiveNumber(v) !== true) {
          return this.fieldRules.positiveNumber(v);
        }

        if (this.fieldRules.isSafeInteger(v) !== true) {
          return this.fieldRules.isSafeInteger(v);
        }

        if (+v > MAX_NUM_CARDS_BATCH_ISSUE) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.max_value",
            MAX_NUM_CARDS_BATCH_ISSUE
          );
        }

        if (+v < MIN_NUM_CARDS_BATCH_ISSUE) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.min_value",
            MIN_NUM_CARDS_BATCH_ISSUE
          );
        }

        return true;
      },
      autoRefillThreshold: (v: string) => {
        if (+v > MAX_AUTO_REFILL_THRESHOLD) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.max_amount",
            formatMoney({
              value: MAX_AUTO_REFILL_THRESHOLD,
              currency: this.currentCurrency,
            })
          );
        }

        if (+v < MIN_AUTO_REFILL_THRESHOLD) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.min_amount",
            formatMoney({
              value: MIN_AUTO_REFILL_THRESHOLD,
              currency: this.currentCurrency,
            })
          );
        }

        return true;
      },
      autoRefillAmount: (v: string) => {
        if (+v > MAX_AUTO_REFILL_AMOUNT) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.max_amount",
            formatMoney({
              value: MAX_AUTO_REFILL_AMOUNT,
              currency: this.currentCurrency,
            })
          );
        }

        if (+v < MIN_AUTO_REFILL_AMOUNT) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.min_amount",
            formatMoney({
              value: MIN_AUTO_REFILL_AMOUNT,
              currency: this.currentCurrency,
            })
          );
        }

        return true;
      },
    };
  }

  private async onChangeCardGroup(val: string | CardGroup) {
    this.form.cardGroup = val;
  }

  private setLoading(loading: boolean) {
    this.loading = loading;
    this.$emit("update:loading", loading);
  }

  private validateFormCardLimit() {
    return !this.formCardLimitRef || this.formCardLimitRef.validate();
  }

  private async checkBatchIssueCard() {
    if (
      this.loading ||
      this.isInsufficientBalance ||
      !this.validateFormCardLimit() ||
      !this.selectedBank
    ) {
      return false;
    }

    this.setLoading(true);

    await this.fetchAvailablePromocodesAction();
    await this.$nextTick();

    if (!this.validateFormCardLimit()) {
      this.setLoading(false);

      return false;
    }

    this.setLoading(false);

    return true;
  }

  private async batchIssueCard() {
    const checked = await this.checkBatchIssueCard();

    if (!checked || !this.selectedBank) return;

    this.setLoading(true);

    try {
      const { depositAmount, cardGroup, ...data } = this.form;
      const { id: bankId } = this.selectedBank;

      let cardGroupId: number | undefined;

      if (typeof cardGroup === "string") {
        const { id: newCardGroupId } = await this.createCardGroupAction({
          name: cardGroup,
        });

        cardGroupId = newCardGroupId;
      } else {
        cardGroupId = cardGroup?.id || undefined;
      }

      await API.card.batchIssueCard({
        ...data,
        cardType: CardType.PREPAID,
        depositAmount: +depositAmount,
        bankId,
        cardGroupId,
      });

      this.fetchWalletsAction();

      this.setLoading(false);
      this.completed = true;
      this.$emit("issued");
    } catch (error) {
      this.setLoading(false);
    }
  }

  private onClose() {
    this.$emit("close");
  }

  private created() {
    this.form.depositAmount = this.minDepositAmount.toString();

    this.fetchUserBanksAction();
    this.fetchAvailablePromocodesAction();
    this.fetchProfileAction();
  }
}
